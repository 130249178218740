import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { Card, Table, Spinner } from 'react-bootstrap';
import { ResponsiveContainer } from 'recharts';
import SpinnerVision from '../../Spinner';

interface Proposta {
  val_bruto: number;
  dsc_situacao_emprestimo: string;
}

const SituacaoDataTableApoioCorban: React.FC = () => {
  const [data, setData] = useState<Proposta[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [codSupervisor, setCodSupervisor] = useState<string | null>(localStorage.getItem('codSupervisor'));
  const [base, setBase] = useState<string>(localStorage.getItem('selectedBase') || format(new Date(), 'yyyyMM'));
  const [selectedPartner, setSelectedPartner] = useState<string>(localStorage.getItem('selectedPartner') || '');
  const [intervalId, setIntervalId] = useState<any | null>(null);

  // Função para buscar dados
  const fetchData = async (base: string, selectedPartner: string) => {
    try {
      setIsLoading(true);
      let url = `https://bi.gfttech.com.br/api/v2/apoioCorban/producao?base=${base}`;
      if (selectedPartner) {
        url += `&codProdutorVenda=${selectedPartner}`;
      }

      const response = await fetch(url);
      const result = await response.json();

      // Agrupar os dados por situação de empréstimo e somar os valores brutos
      const groupedData: { [key: string]: number } = {};
      result.forEach((proposta: any) => {
        let situacao = proposta.dsc_situacao_emprestimo;
        if (situacao === "CRC C/ PENDÊNCIA") {
          situacao = "CR CLIENTE";
        } 
        if (situacao === "AUTORREGULAÇÃO") situacao = "PAGO";
        if (situacao === null) situacao = "OUTROS";
        else if (situacao === "PAGO C/PENDÊNCIA") {
          situacao = "PAGO";
        }
        if (!groupedData[situacao]) {
          groupedData[situacao] = 0;
        }
        groupedData[situacao] += proposta.val_bruto;
      });

      // Convertendo o objeto agrupado em um array e ordenando
      const processedData = Object.keys(groupedData)
        .map(key => ({
          dsc_situacao_emprestimo: key,
          val_bruto: groupedData[key]
        }))
        .sort((a, b) => b.val_bruto - a.val_bruto);

      setData(processedData);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Efeito para verificar mudanças no localStorage a cada segundo
  useEffect(() => {
    const interval = setInterval(() => {
      const storedBase = localStorage.getItem('selectedBase');
      const storedSelectedPartner = localStorage.getItem('selectedPartner');

      if (storedBase !== base) {
        setBase(storedBase || format(new Date(), 'yyyyMM'));
      }

      if (storedSelectedPartner !== selectedPartner) {
        setSelectedPartner(storedSelectedPartner || '');
      }
    }, 1000); // Verifica a cada segundo

    setIntervalId(interval);

    return () => clearInterval(interval);
  }, [base, selectedPartner]);

  // Efeito para buscar dados quando base, codSupervisor ou selectedPartner mudam
  useEffect(() => {
    fetchData(base, selectedPartner);
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [base, selectedPartner, intervalId]);

  return (
    <>
      <Card className='mt-5'>
        <Card.Header>
          Produção por Situação de Empréstimo
        </Card.Header>
        <Card.Body style={{ paddingBottom: "30px" }}>
          <ResponsiveContainer width="100%" height="100%">
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                <SpinnerVision/>
              </div>
            ) : (
              <div style={{ minHeight: "300px", maxHeight: "300px", overflowY: "auto" }}>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Situação</th>
                      <th>Valor Bruto</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((proposta, index) => (
                      <tr key={index}>
                        <td>{proposta.dsc_situacao_emprestimo}</td>
                        <td>{proposta.val_bruto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </ResponsiveContainer>
        </Card.Body>
      </Card>
    </>
  );
};

export default SituacaoDataTableApoioCorban;
