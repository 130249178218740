import React, { useState, useEffect } from "react";
import { Table, Collapse } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import SpinnerVision from "../../Spinner";

interface Proposta {
    nom_banco: string;
    dsc_grupo: string;
    val_bruto: number;
    nom_produtor_venda: string;
}

const DataBancoConvenioApoioCorban: React.FC = () => {
    const [baseState, setBaseState] = useState<string | null>(localStorage.getItem("selectedBase"));
    const [selectedPartner, setSelectedPartner] = useState<string>(localStorage.getItem("selectedPartner") || "");
    const [bankConvenioData, setBankConvenioData] = useState<{
        banco: string;
        convenio: string;
        total: number;
        parceiros: { nom_produtor_venda: string; total: number }[];
    }[]>([]);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const interval = setInterval(() => {
            const newBase = localStorage.getItem("selectedBase");
            const newPartner = localStorage.getItem("selectedPartner");

            if (newBase !== baseState) {
                setBaseState(newBase);
            }
            if (newPartner !== selectedPartner) {
                setSelectedPartner(newPartner || "");
            }
        }, 1000);

        return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }, [baseState, selectedPartner]);

    useEffect(() => {
        const fetchData = async () => {
            if (!baseState) return;

            try {
                setIsLoading(true);
                const url = `https://bi.gfttech.com.br/api/v2/apoioCorban/producao?base=${baseState}`;
                const response = await fetch(url);
                const data: Proposta[] = await response.json();

                const groupedData: {
                    [key: string]: {
                        banco: string;
                        convenio: string;
                        total: number;
                        parceiros: { nom_produtor_venda: string; total: number }[];
                    };
                } = {};

                data.forEach((proposta) => {
                    const key = `${proposta.nom_banco} - ${proposta.dsc_grupo}`;
                    if (!groupedData[key]) {
                        groupedData[key] = {
                            banco: proposta.nom_banco,
                            convenio: proposta.dsc_grupo,
                            total: 0,
                            parceiros: [],
                        };
                    }
                    groupedData[key].total += proposta.val_bruto;

                    const parceiroIndex = groupedData[key].parceiros.findIndex(
                        (p) => p.nom_produtor_venda === proposta.nom_produtor_venda
                    );
                    if (parceiroIndex === -1) {
                        groupedData[key].parceiros.push({
                            nom_produtor_venda: proposta.nom_produtor_venda,
                            total: proposta.val_bruto,
                        });
                    } else {
                        groupedData[key].parceiros[parceiroIndex].total += proposta.val_bruto;
                    }
                });

                const aggregatedData = Object.values(groupedData);
                setBankConvenioData(aggregatedData.sort((a, b) => b.total - a.total));
            } catch (error) {
                console.error("Erro ao buscar dados das propostas:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [baseState, selectedPartner]);

    const toggleRow = (rowKey: string) => {
        setExpandedRow(expandedRow === rowKey ? null : rowKey);
    };

    return (
        <>
            <h5 className="text-center display-6 pb-4" style={{ fontSize: "28px" }}>
                Ranking Banco/Convênio
            </h5>
            <div style={{ maxHeight: "410px", overflowY: "auto" }}>
                {isLoading ? (
                    <div className="d-flex justify-content-center">
                        <SpinnerVision />
                    </div>
                ) : (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Banco</th>
                                <th>Convênio</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankConvenioData.map((item, index) => (
                                <React.Fragment key={index}>
                                    <tr
                                        onClick={() => toggleRow(`${item.banco} - ${item.convenio}`)}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <td>
                                            <FontAwesomeIcon
                                                icon={expandedRow === `${item.banco} - ${item.convenio}` ? faChevronUp : faChevronDown}
                                            />
                                            {item.banco}
                                        </td>
                                        <td>{item.convenio}</td>
                                        <td>{item.total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} style={{ padding: 0, border: 0 }}>
                                            <Collapse in={expandedRow === `${item.banco} - ${item.convenio}`}>
                                                <div>
                                                    <Table striped bordered hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ backgroundColor: "#062173", color: "white" }}>Parceiro</th>
                                                                <th style={{ backgroundColor: "#062173", color: "white" }}>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item.parceiros.map((parceiro, idx) => (
                                                                <tr key={idx}>
                                                                    <td>{parceiro.nom_produtor_venda}</td>
                                                                    <td>
                                                                        {parceiro.total.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Collapse>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </>
    );
};

export default DataBancoConvenioApoioCorban;
