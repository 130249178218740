import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import ModalOperacaoGerente from "../../ModalsComercial/ModalOperacaoGerente";

interface ProducaoOperacaoProps {
  data: { operacao: string; val_bruto: number }[];
  dataModal?: { produtor: string; operacoes: { operacao: string; val_bruto: number }[] }[];
}

const ProducaoOperacao: React.FC<ProducaoOperacaoProps> = ({ data, dataModal }) => {
  const [modalData, setModalData] = useState<{ produtor: string; operacoes: { operacao: string; val_bruto: number }[] }[] | null>(null);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sortedData = data.sort((a, b) => b.val_bruto - a.val_bruto);
  const colors = ["#062173", "#0F6DFC"];

  // Função para formatar valores como moeda
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    })
      .format(value)
      .replace("R$", "")
      .trim();
  };

  // Função de clique para abrir o modal com dados específicos
  const handleBarClick = (operacao: string) => {
    if (dataModal) {
      const filteredData = dataModal.map((produtor) => ({
        produtor: produtor.produtor,
        operacoes: produtor.operacoes.filter((op) => op.operacao === operacao),
      }));

      setModalTitle(operacao);
      setModalData(filteredData);
      setIsModalOpen(true);
    }
  };

  // Calcula a largura das barras proporcional ao valor
  const maxValBruto = sortedData[0].val_bruto;
  const calculateBarSize = (value: number, maxValue: number) => {
    const minBarWidth = 100;  // Largura mínima da barra
    const maxBarWidth = 300; // Largura máxima da barra
    const barSize = (value / maxValue) * maxBarWidth;
    return Math.max(minBarWidth, barSize); // Garantir que o tamanho da barra não seja menor que a largura mínima
  };

  const renderCustomLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    const isOutside = value < maxValBruto * 0.15; // Condição para exibir fora

    const labelX = isOutside ? x + width + 10 : x + width / 2; // Ajuste da posição X
    const labelY = y + height / 2 + 5; // Centralizar no eixo Y

    const labelStyle = {
      fontSize: isOutside ? "14px" : "12px",
      fontWeight: "bold",
      fill: isOutside ? "#062173" : "#fff", // Cor diferente para fora
      background: isOutside ? "#f1f1f1" : "none", // Fundo para destacar
      padding: isOutside ? "2px 4px" : "0",
      borderRadius: isOutside ? "4px" : "0",
    };

    return (
      <text
        x={labelX}
        y={labelY}
        style={labelStyle}
        dominantBaseline="middle"
        textAnchor={isOutside ? "start" : "middle"} // Ajuste para textAnchor
      >
        {formatCurrency(value)}
      </text>
    );
  };

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={sortedData} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" tickFormatter={formatCurrency} />
          <YAxis type="category" dataKey="operacao" width={150} />
          <Tooltip formatter={(value: number) => formatCurrency(value)} />
          <Bar
            dataKey="val_bruto"
            fill="#032064"
            onClick={(e) => handleBarClick(e.payload.operacao)}
            style={{ cursor: "pointer" }}
          >
            {sortedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
                width={calculateBarSize(entry.val_bruto, maxValBruto)} // Aplicando a largura dinâmica às barras
              />
            ))}
            <LabelList
              dataKey="val_bruto"
              content={(props) => renderCustomLabel(props)} // Usando renderCustomLabel para exibir os labels
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      {isModalOpen && modalData && (
        <ModalOperacaoGerente
          data={modalData}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default ProducaoOperacao;
