import { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import DailyPartnerTyping from "../../components/Digitacao/Diaria";
import FilterApoioCorban from "../../components/FilterApoioCorban";
import { DailyData } from "../../interfaces/interfaces";
import NavBarApoioCorban from "../../components/NavBar/ApoioCorban";

export default function TypingApoioCorban() {
  const [selectedBase, setSelectedBase] = useState(localStorage.getItem("selectedBase") || "");
  const [selectedPartner, setSelectedPartner] = useState(localStorage.getItem("selectedPartner") || "");
  const [dailyData, setDailyData] = useState<DailyData[]>([]);

  const fetchDailyData = async (base: string) => {
    const endpoint = selectedPartner
      ? `https://bi.gfttech.com.br/api/v2/apoioCorban/DigitacaoDiaria?base=${base}&partner=${selectedPartner}`
      : `https://bi.gfttech.com.br/api/v2/apoioCorban/DigitacaoSemanal?base=${base}`;

    try {
      const response = await fetch(endpoint);
      const data = await response.json();

      if (Array.isArray(data)) {
        const formattedData = data.map((item) => ({
          date: new Date(item.data_digitacao).toISOString().split("T")[0], // Formato 'YYYY-MM-DD'
          producer: item.nom_produtor_venda,
          producaoBruta: item.producao_bruta || 0, // Garantindo que 'producaoBruta' tenha um valor
        }));
        setDailyData(formattedData);
      } else {
        console.warn("Formato inesperado da API:", data);
      }
    } catch (error) {
      console.error("Erro ao buscar os dados:", error);
    }
  };

  useEffect(() => {
    if (selectedBase) {
      fetchDailyData(selectedBase);
    }
  }, [selectedBase, selectedPartner]);

  const handleBaseChange = (newBase: string) => {
    setSelectedBase(newBase);
    localStorage.setItem("selectedBase", newBase);
  };

  return (
    <>
      <NavBarApoioCorban />
      <Container className="mt-5 mb-5">
        <Row className="mb-4">
          <FilterApoioCorban onBaseChange={handleBaseChange} />
        </Row>
        <Row>
          <h3 className="mb-4">Digitação Diária</h3>
          <DailyPartnerTyping data={dailyData} /> {/* Passando o data corretamente */}
        </Row>
      </Container>
    </>
  );
}
