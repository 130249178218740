import { useState, useEffect } from "react";
import axios from "axios";
import styles from './Login.module.scss';
import logo from '../../assets/images/logo-vision-bi.png';
import logoBi from '../../assets/images/logoMainLogin.png';
import ButtonLogin from "../../components/ButtonLogin";
import { FloatingLabel, Form } from "react-bootstrap";
import FirstAccess from "../../components/FirstAccess";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isFirstAccess, setIsFirstAccess] = useState(false);
  const [userId, setUserId] = useState<number | null>(null);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLogin(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const isFormValid = () => {
    return login.trim() !== '' && password.trim() !== '';
  };

  const handleLogin = async () => {
    if (login === 'indicador') {
      localStorage.setItem('codIndicador', '5470');
      window.location.href = '/HomeIndicador';
      return;
    }
    if (login === 'parceiro') {
      localStorage.setItem('codParceiro', '6310');
      window.location.href = '/HomeParceiro';
      return;
    }
  
    try {
      const response = await axios.post('https://webservices.gfttech.com.br/api/v1/logar', {
        email: login,
        password: password
      });
  
      if (response.data.authorization) {
        const token = response.data.authorization;
        localStorage.setItem('token', token);
        localStorage.setItem('isMenuOpen', 'true');
  
        // Obter dados do usuário com base no token
        const userDataResponse = await axios.get('https://webservices.gfttech.com.br/api/v1/parceiro/dados-logado', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        const userData = userDataResponse.data;
        const userId = userData.employeeData ? userData.employeeData.user_id : userData.partnerData.user_id;
        setUserId(userId);
  
        if (password === 'gft@2024') {
          // Caso de primeiro acesso
          setIsFirstAccess(true);
        } else {
          // Buscar o código externo e a posição do usuário
          const externalCodeResponse = await axios.get('https://webservices.gfttech.com.br/api/v1/external-code', {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
  
          const { ExternalCode, Position } = externalCodeResponse.data;
  
          // Redirecionamento com base na posição do usuário
          switch (Position) {
            case 'Gerente Comercial':
              localStorage.setItem('codSupervisor', ExternalCode);
              window.location.href = '/HomeGerente';
              break;
            case 'Gerente Regional':
              localStorage.setItem('codRegional', ExternalCode);
              window.location.href = '/HomeRegional';
              break;
            case 'Superintendente':
              localStorage.setItem('codSuperintendente', ExternalCode);
              window.location.href = '/HomeSup';
              break;
            case 'Parceiro':
              localStorage.setItem('codParceiro', ExternalCode);
              window.location.href = '/HomeParceiro';
              break;
            case 'Apoio Corban':
              window.location.href = '/HomeApoioCorban';
              break;
            default:
              setError('Posição inválida. Por favor, contate o suporte.');
          }
        }
      } else {
        setError('Token de autorização não encontrado na resposta.');
      }
    } catch (error) {
      setError('Erro ao realizar login. Por favor, verifique suas credenciais e tente novamente.');
    }
  };  

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && isFormValid()) {
      handleLogin();
    }
  };

  return (
    <div className={styles.mainSectionLogin}>
      <div className={styles.logoSection}>
        <img src={logoBi} />
      </div>
      <div className={`${styles.containerLogin} p-5`}>
        <img src={logo} alt="Logo" />
        {isFirstAccess && userId ? (
          <FirstAccess userId={userId} />
        ) : (
          <div className={styles.inputWithIcon}>
            <FloatingLabel controlId="floatingInput" label="Email" className="mb-4">
              <Form.Control
                type="text"
                placeholder="Email"
                value={login}
                onChange={handleLoginChange}
                className="form-control"
              />
            </FloatingLabel>
            <FloatingLabel controlId="floatingPassword" label="Senha" className="mb-4">
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder="Senha"
                value={password}
                onChange={handlePasswordChange}
                onKeyDown={handleKeyPress}
                className="form-control"
              />
            </FloatingLabel>
          </div>
        )}
        {error && <p className="text-danger text-center mt-5">{error}</p>}
        {!isFirstAccess && (
          <ButtonLogin
            content="Entrar"
            onClick={handleLogin}
            disabled={!isFormValid()}
          />
        )}
      </div>
    </div>
  );
}
