import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import ModalConvenioGerente from "../../ModalsComercial/ModalConvenioGerente";

interface ProducaoConvenioBarProps {
  data: { convenio: string; val_bruto: number }[];
  dataModal?: {
    produtor: string;
    convenios: { convenio: string; val_bruto: number }[];
  }[];
}

const ProducaoConvenioBar: React.FC<ProducaoConvenioBarProps> = ({ data, dataModal }) => {
  const [modalData, setModalData] = useState<
    { produtor: string; total: number }[] | null
  >(null);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sortedData = data.sort((a, b) => b.val_bruto - a.val_bruto);
  const colors = ["#062173", "#0F6DFC"];
  const formatCurrency = (value: number) =>
    new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    })
      .format(value)
      .replace("R$", "")
      .trim();

  const handleBarClick = (convenio: string) => {
    if (dataModal) {
      const produtorData = dataModal
        .filter((item) =>
          item.convenios.some((conv) => conv.convenio === convenio)
        )
        .map((item) => {
          const convenios = item.convenios.filter(
            (conv) => conv.convenio === convenio
          );
          const totalProducao = convenios.reduce(
            (acc, curr) => acc + curr.val_bruto,
            0
          );

          return {
            produtor: item.produtor,
            total: totalProducao,
          };
        });

      if (produtorData.length > 0) {
        setModalTitle(convenio);
        setModalData(produtorData);
        setIsModalOpen(true);
      }
    }
  };

  const maxValBruto = sortedData[0].val_bruto;

  const calculateBarSize = (value: number, maxValue: number) => {
    const minBarWidth = 100; // Aumentando a largura mínima das barras
    const maxBarWidth = 300; // Aumentando a largura máxima das barras
    const barSize = (value / maxValue) * maxBarWidth;
    return Math.max(minBarWidth, barSize); // Garantir que o tamanho da barra não seja menor que a largura mínima
  };
  

  const renderCustomLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    const isOutside = value < maxValBruto * 0.15; // Condição para exibir fora
  
    const labelX = isOutside ? x + width + 10 : x + width / 2; // Ajuste da posição X
    const labelY = y + height / 2 + 5; // Centralizar no eixo Y
  
    const labelStyle = {
      fontSize: isOutside ? "14px" : "12px",
      fontWeight: "bold",
      fill: isOutside ? "#062173" : "#fff", // Cor diferente para fora
      background: isOutside ? "#f1f1f1" : "none", // Fundo para destacar
      padding: isOutside ? "2px 4px" : "0",
      borderRadius: isOutside ? "4px" : "0",
    };
  
    return (
      <text
        x={labelX}
        y={labelY}
        style={labelStyle}
        dominantBaseline="middle"
        textAnchor={isOutside ? "start" : "middle"} // Ajuste para textAnchor
      >
        {formatCurrency(value)}
      </text>
    );
  };
  

  return (
    <>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={sortedData} layout="vertical">
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" />
          <YAxis type="category" dataKey="convenio" width={150} />
          <Tooltip formatter={(value: number) => formatCurrency(value)} />
          <Bar
            dataKey="val_bruto"
            fill="#032064"
            onClick={(e) => handleBarClick(e.payload.convenio)}
            style={{ cursor: "pointer" }}
          >
            {sortedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index % colors.length]}
                width={calculateBarSize(entry.val_bruto, maxValBruto)}
              />
            ))}
            <LabelList
              dataKey="val_bruto"
              content={(props) => renderCustomLabel(props)}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>

      {isModalOpen && modalData && (
        <ModalConvenioGerente
          data={modalData}
          modalTitle={modalTitle}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </>
  );
};

export default ProducaoConvenioBar;
