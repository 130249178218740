import { useEffect, useState } from "react";
import { Container, Row, Table, Col, Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import NavBar from "../../components/NavBar/GerenteComercial";
import FilterComercial from "../../components/FilterComercial";
import styles from "./Styling.module.scss";
import logoVisionBi from "../../assets/images/logo-vision-bi.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import DailyPartnerTyping from "../../components/Digitacao/Diaria";
import ComparativoMesAno from "../../components/Digitacao/ComparativoMesAno";
import { DailyData } from "../../interfaces/interfaces";

export default function Typing() {
  const [selectedBase, setSelectedBase] = useState(localStorage.getItem("selectedBase"));
  const [selectedPartner, setSelectedPartner] = useState(localStorage.getItem("selectedPartner"));
  const codSupervisor = localStorage.getItem("codSupervisor");
  const [dailyData, setDailyData] = useState<DailyData[]>([]);

  // Função para verificar e atualizar o estado baseado no localStorage
  const checkLocalStorageForUpdates = () => {
    const storedBase = localStorage.getItem("selectedBase");
    const storedPartner = localStorage.getItem("selectedPartner");

    if (storedBase !== selectedBase) {
      setSelectedBase(storedBase || "");
    }
    if (storedPartner !== selectedPartner) {
      setSelectedPartner(storedPartner || "");
    }
  };

  // Utiliza um intervalo para verificar atualizações do localStorage a cada 2 segundos
  useEffect(() => {
    const intervalId = setInterval(checkLocalStorageForUpdates, 2000);
    return () => clearInterval(intervalId);
  }, [selectedBase, selectedPartner]);

  // Effect to fetch data whenever selectedBase or selectedPartner changes
  useEffect(() => {
    if (selectedBase) {
      fetchDailyData(selectedBase);
    }
  }, [selectedBase, selectedPartner]);

  const fetchDailyData = async (base: any) => {
    const endpoint = selectedPartner
      ? `https://bi.gfttech.com.br/api/v2/gerentes/DigitacaoDiaria?codSupervisor=${codSupervisor}&mesAno=${selectedBase}&codProdutorVenda=${selectedPartner}`
      : `https://bi.gfttech.com.br/api/v2/gerentes/DigitacaoDiaria?codSupervisor=${codSupervisor}&mesAno=${selectedBase}`;

    const data = await fetchData(endpoint);

    if (selectedPartner) {
      const processedData = data.map((item: any) => ({
        date: item.data_digitacao,
        producer: item.nom_produtor_venda || "Desconhecido",
        producao_bruta: parseFloat(item.producao_bruta) || 0,
      }));
      setDailyData(processedData);
    } else {
      const groupedData = data.reduce((acc: any, item: any) => {
        const existingDay = acc.find((entry: any) => entry.date === item.data_digitacao);
        if (existingDay) {
          existingDay.producao_bruta += parseFloat(item.producao_bruta) || 0;
        } else {
          acc.push({
            date: item.data_digitacao,
            producer: "Todos",
            producao_bruta: parseFloat(item.producao_bruta) || 0,
          });
        }
        return acc;
      }, []);
      setDailyData(groupedData);
    }
  };

  const fetchData = async (endpoint: string) => {
    const response = await fetch(endpoint);
    if (!response.ok) {
      throw new Error("Failed to fetch data");
    }
    return await response.json();
  };

  const handleBaseChange = (newBase: string) => {
    setSelectedBase(newBase);
    localStorage.setItem("selectedBase", newBase ?? "");
  };

  // Função para calcular a variação percentual
  const renderPercentageChange = (current: number, previous: number) => {
    if (previous === 0) {
      return <span className="text-muted">-</span>;
    }

    const change = ((current - previous) / previous) * 100;
    const isImproved = change > 0;

    return (
      <span>
        {isImproved ? (
          <FontAwesomeIcon icon={faArrowUp} className="text-success" />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} className="text-danger" />
        )}{" "}
        {change.toFixed(2)}%
      </span>
    );
  };

  function formatDateUTC(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Mês é zero-indexado
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  }

  function formatCurrency(value: any) {
    if (value == null || isNaN(value)) return '-'; // Retorna um traço para valores inválidos
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }

  // Ordenando os dados de forma crescente pela data
  const sortedData = [...dailyData].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  return (
    <>
      <NavBar />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5 mb-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{ maxWidth: "270px" }} alt="Logo Vision BI" />
            </div>
          </Row>

          <Row className="mb-5">
            <FilterComercial onBaseChange={handleBaseChange} />
          </Row>

          {/* Digitação Diária */}
          <Row>
            <h3 className="mb-4">Digitação Diária</h3>
            <DailyPartnerTyping data={sortedData}/>
          </Row>

          {/* Comparativo Mês/Ano */}
          <Row>
            <ComparativoMesAno/>
          </Row>
        </Container>
      </motion.div>
    </>
  );
}
