import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Typing from "./pages/GerenteComercial/Typing";
import BankAnalytics from "./pages/GerenteComercial/BankAnalytics";
import AgentAnalytics from "./pages/GerenteComercial/AgentAnalytics";
import { AnimatePresence } from "framer-motion";
import SituationAnalytics from "./pages/GerenteComercial/SituationAnalytics";
import Projection from "./pages/GerenteComercial/Projection";
import Login from "./pages/Login";
import HomeRegional from "./pages/Regional";
import BankAnalyticsRegional from "./pages/Regional/BankAnalyticsRegional";
import AgentAnalyticsRegional from "./pages/Regional/AgentAnalyticsRegional";
import SituationAnalyticsRegional from "./pages/Regional/SituationAnalyticsRegional";
import ProjectionRegional from "./pages/Regional/ProjectionRegional";
import HomeSuperintendente from "./pages/Superintendente";
import AgentAnalyticsSuperintendente from "./pages/Superintendente/AgentAnalyticsSuperintendente";
import BankAnalyticsSuperintendente from "./pages/Superintendente/BankAnalyticsSuperintendente";
import SituationAnalyticsSuperintendente from "./pages/Superintendente/SituationAnalyticsSuperintendente";
import GerenteComercial from "./pages/GerenteComercial";
import HomeIndicador from "./pages/Parceiro/Indicador";
import BankAnalyticsIndicador from "./pages/Parceiro/Indicador/BankAnalyticsIndicador";
import AgentAnalyticsIndicador from "./pages/Parceiro/Indicador/AgentAnalyticsIndicador";
import SettingsIndicador from "./pages/Parceiro/Indicador/SettingsIndicador";
import HomeParceiro from "./pages/Parceiro/Parceiro";
import BankAnalyticsParceiro from "./pages/Parceiro/Parceiro/BankAnalyticsParceiro";
import SettingsParceiro from "./pages/Parceiro/Parceiro/SettingsParceiro";
import SituationAnalyticsParceiro from "./pages/Parceiro/Parceiro/SituationAnalytics Parceiro";
import MyManagers from "./pages/Regional/MyManagers";
import ApoioCorban from "./pages/ApoioCorban";
import TypingApoioCorban from "./pages/ApoioCorban/Typing";
import BankAnalyticsApoioCorban from "./pages/ApoioCorban/BankAnalytics";
import SituationAnalyticsApoioCorban from "./pages/ApoioCorban/SituationAnalytics";

interface RouteComponentProps {
  perfil?: string;
}

const RouteComponent: React.FC<RouteComponentProps> = ({ perfil }) => {
  return (
    <BrowserRouter>
      <App perfil={perfil} />
    </BrowserRouter>
  );
};

const App: React.FC<RouteComponentProps> = () => {

  const location = useLocation();
  return (
    <div className="App">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Login />} />
          <Route path="/HomeGerente" element={<GerenteComercial />} />
          <Route path="/HomeRegional" element={<HomeRegional />} />
          <Route path="/HomeSup" element={<HomeSuperintendente />} />
          <Route path="/Digitacao" element={<Typing/>}/>
          <Route path="/AnaliticoBanco" element={<BankAnalytics/>}/>
          <Route path="/AnaliticoBancoRegional" element={<BankAnalyticsRegional/>}/>
          <Route path="/AnaliticoBancoSuperintendente" element={<BankAnalyticsSuperintendente/>}/>
          <Route path="/AnaliticoAgente" element={<AgentAnalytics/>}/>
          <Route path="/AnaliticoAgenteRegional" element={<AgentAnalyticsRegional/>}/>
          <Route path="/AnaliticoAgenteSuperintendente" element={<AgentAnalyticsSuperintendente/>}/>
          <Route path="/AnaliticoSituacao" element={<SituationAnalytics/>}></Route>
          <Route path="/AnaliticoSituacaoRegional" element={<SituationAnalyticsRegional/>}></Route>
          <Route path="/AnaliticoSituacaoSuperintendente" element={<SituationAnalyticsSuperintendente/>}></Route>
          <Route path="/MetaProjecao" element={<Projection/>}></Route>
          <Route path="/MetaProjecaoRegional" element={<ProjectionRegional/>}></Route>
          <Route path="/MeusGerentesReg" element={<MyManagers/>}></Route>


          {/* Agentes */}
          <Route path="/HomeParceiro" element={<HomeParceiro/>}></Route>
          <Route path="/AnaliticoBancoParceiro" element={<BankAnalyticsParceiro/>}/>
          <Route path="/ConfiguracoesParceiro" element={<SettingsParceiro/>}/>
          <Route path="/AnaliticoSituacaoParceiro" element={<SituationAnalyticsParceiro/>}/>
        
          {/* Indicador */}
          <Route path="/HomeIndicador" element={<HomeIndicador/>}></Route>
          <Route path="/AnaliticoBancoIndicador" element={<BankAnalyticsIndicador/>}/>
          <Route path="/AnaliticoIndicados" element={<AgentAnalyticsIndicador/>}/>
          <Route path="/ConfiguracoesIndicador" element={<SettingsIndicador/>}/>
          
          {/* Apoio Corban*/}
          <Route path="/HomeApoioCorban" element={<ApoioCorban/>}></Route>
          <Route path="/DigitacaoApoioCorban" element={<TypingApoioCorban/>}></Route>
          <Route path="/AnaliticoBancoApoioCorban" element={<BankAnalyticsApoioCorban/>}></Route>
          <Route path="/AnaliticoSituacaoApoioCorban" element={<SituationAnalyticsApoioCorban/>}></Route>
          
        </Routes>
      </AnimatePresence>
    </div>
  );
};

export default RouteComponent;
