import React, { useState } from 'react';
import {  Col, Container, Row } from 'react-bootstrap';
import { motion } from 'framer-motion';
import styles from './Styling.module.scss'
import logoVisionBi from '../../../assets/images/logo-vision-bi.png'
import FilterParceiro from '../../../components/FilterParceiro';
import NavBarParceiro from '../../../components/NavBar/Parceiro';
import SituacaoDataTableParceiro from '../../../components/GraficosParceiros/Parceiro_Sub/SituacaoDataTableParceiro';
import SituacaoValorProducaoBarChartParceiro from '../../../components/GraficosParceiros/Parceiro_Sub/SituacaoValorProducaoBarChart';
import SituacaoPizzaParceiro from '../../../components/GraficosParceiros/Parceiro_Sub/SituacaoPizzaParceiro';
import SituacaoIndicesAnualParceiro from '../../../components/GraficosParceiros/Parceiro_Sub/SituacaoIndiceAnualParceiro';

const SituationAnalyticsParceiro: React.FC = () => {
  const [base, setBase] = useState<string | null>(null);

  const handleBaseChange = (newBase: string | null) => {
    setBase(newBase);
  };

  return (
    <>
      <NavBarParceiro />
      <motion.div
        initial={{ x: 1000, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: window.innerWidth, opacity: 0 }}
        transition={{ duration: 1.2 }}
      >
        <Container className={`${styles.containerMain} mt-5 mb-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{maxWidth: '270px'}}/>
            </div>
          </Row>
          <Row>
            <FilterParceiro onBaseChange={handleBaseChange} />
          </Row>
          <Row>
            <Col md="4">
             <SituacaoDataTableParceiro />
            </Col>
            <Col md="4">
              <SituacaoValorProducaoBarChartParceiro/>
            </Col>
            <Col md="4">
              <SituacaoPizzaParceiro/>
            </Col>
          </Row>
          <Row>
            <SituacaoIndicesAnualParceiro/>
          </Row>
        </Container>
      </motion.div>
    </>
  );
};

export default SituationAnalyticsParceiro;