import React, { useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import NavBar from "../../components/NavBar/GerenteComercial";
import DadosGraficoRosca from "../../components/Graficos/MetaProdGrafico";
import LiquidoRepasse from "../../components/Graficos/LiquidoRepasse";
import AgenteSub from "../../components/Graficos/AgenteSub";
import ProducaoConvenioBar from "../../components/Graficos/ProducaoConvenioBar";
import ProducaoOperacao from "../../components/Graficos/ProducaoOperacao";
import FilterComercial from "../../components/FilterComercial";
import { motion } from "framer-motion";
import { format, getWeek } from "date-fns";
import logoVisionBi from '../../assets/images/logo-vision-bi.png'
import styles from './Styling.module.scss'
import ProducaoTabela from "../../components/Graficos/ProducaoLinha";
import NavBarApoioCorban from "../../components/NavBar/ApoioCorban";
import FilterApoioCorban from "../../components/FilterApoioCorban";

type TypeProd = "Bruto" | "Repasse" | null;
type SelectedPartner = number | null;

interface Proposta {
  val_bruto: number;
  val_repasse: number;
  dsc_tipo_funcao: string;
  cod_produtor_venda: string;
  nom_produtor_venda: string;
  dat_confirmacao: string;
  dsc_grupo: string;
  dsc_tipo_proposta_emprestimo: string;
}

interface ProdMediaDia {
  producaoAteDataAtual: number;
  mediaProducaoAteDataAtual: number; 
}

export default function ApoioCorban() {
  const [producaoData, setProducaoData] = useState<Proposta[]>([]);
  const [valorBruto, setValorBruto] = useState<number>(0);
  const [valorRepasse, setValorRepasse] = useState<number>(0);
  const [producaoAgente, setProducaoAgente] = useState<number>(0);
  const [producaoSubstabelecido, setProducaoSubstabelecido] = useState<number>(0);
  const [producaoPorConvenio, setProducaoPorConvenio] = useState<{ convenio: string; val_bruto: number }[]>([]);
  const [animationComplete, setAnimationComplete] = useState<boolean>(false);
  const [baseSelecionada, setBaseSelecionada] = useState<string>(format(new Date(), 'yyyyMM'));
  const [selectedPartner, setSelectedPartner] = useState<SelectedPartner>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [producaoMediaDia, setProducaoMediaDia] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const storedBase = localStorage.getItem('selectedBase');
      const storedPartner = localStorage.getItem('selectedPartner');

      const menuState = localStorage.getItem('isMenuOpen') === 'true';
        setIsMenuOpen(menuState);

      if (storedBase !== baseSelecionada) {
        setBaseSelecionada(storedBase || format(new Date(), 'yyyyMM'));
      }

      if (storedPartner !== selectedPartner) {
        setSelectedPartner(storedPartner ? parseInt(storedPartner) : null);
      }

     
    }, 1000);

    return () => clearInterval(interval);
  }, [baseSelecionada, selectedPartner]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const idPartner = localStorage.getItem('selectedPartner');
        const typeProdValue = localStorage.getItem('typeProd');

        let url = `https://bi.gfttech.com.br/api/v2/apoioCorban/producao?base=${baseSelecionada}`;
        let urlProdMediaDia = `https://bi.gfttech.com.br/api/v2/apoioCorban/mediaDiariaProducao?base=${baseSelecionada}`

        if (idPartner) {
          url += `&codProdutorVenda=${idPartner}`;
          urlProdMediaDia += `&codProdutorVenda=${idPartner}`
        }

        const response = await fetch(url);
        const responseProdMediaDia = await fetch(urlProdMediaDia);

        const data: Proposta[] = await response.json();

        const dataProdDia: ProdMediaDia = await responseProdMediaDia.json();

        if (!responseProdMediaDia.ok) {
          throw new Error('Erro ao buscar dados de produção média diária');
        }

        setProducaoMediaDia(dataProdDia.mediaProducaoAteDataAtual)
        // Atualização dos estados conforme o resultado da busca
        const filteredData = data.filter(proposta => {
          if (typeProdValue === "Bruto") {
            return proposta.val_bruto > 0;
          } else if (typeProdValue === "Repasse") {
            return proposta.val_repasse > 0;
          } else {
            return true;
          }
        });

        setProducaoData(filteredData);

        const valorTotalBruto = filteredData.reduce((acc, proposta) => acc + proposta.val_bruto, 0);
        const valorTotalRepasse = filteredData.reduce((acc, proposta) => acc + proposta.val_repasse, 0);
        const totalProducaoAgente = filteredData.filter(proposta => proposta.dsc_tipo_funcao === 'AGENTE DE CRÉDITO').reduce((acc, proposta) => acc + proposta.val_bruto, 0);
        const totalProducaoSubstabelecido = filteredData.filter(proposta => proposta.dsc_tipo_funcao === 'SUBSTABELECIDO').reduce((acc, proposta) => acc + proposta.val_bruto, 0);

        setValorBruto(valorTotalBruto);
        setValorRepasse(valorTotalRepasse);
        setProducaoAgente(totalProducaoAgente);
        setProducaoSubstabelecido(totalProducaoSubstabelecido);

        const producaoPorConvenioData = filteredData.reduce((acc: { [key: string]: number }, proposta) => {
          if (!acc[proposta.dsc_grupo]) {
            acc[proposta.dsc_grupo] = 0;
          }
          acc[proposta.dsc_grupo] += proposta.val_bruto;
          return acc;
        }, {});

        const producaoPorConvenioArray = Object.entries(producaoPorConvenioData).map(([convenio, val_bruto]) => ({ convenio, val_bruto }));

        setProducaoPorConvenio(producaoPorConvenioArray);

      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    };

    fetchData();
  }, [baseSelecionada, selectedPartner]);

  const handleAnimationComplete = () => {
    setAnimationComplete(true);
  };

  const producaoPorOperacao = producaoData.reduce((acc, proposta) => {
    const { dsc_tipo_proposta_emprestimo, val_bruto } = proposta;
    const existingOperacao = acc.find((item) => item.operacao === dsc_tipo_proposta_emprestimo);
    if (existingOperacao) {
      existingOperacao.val_bruto += val_bruto;
    } else {
      acc.push({ operacao: dsc_tipo_proposta_emprestimo, val_bruto });
    }
    return acc;
  }, [] as { operacao: string; val_bruto: number }[]);

  const producaoPorProdutor = producaoData.reduce((acc, proposta) => {
    const { nom_produtor_venda, dsc_tipo_proposta_emprestimo, val_bruto } = proposta;
  
    // Verifica se o produtor já existe no acumulador
    const existingProdutor = acc.find(
      (item) => item.produtor === nom_produtor_venda
    );
  
    if (existingProdutor) {
      // Verifica se a operação já existe para o produtor
      const existingOperacao = existingProdutor.operacoes.find(
        (item) => item.operacao === dsc_tipo_proposta_emprestimo
      );
  
      if (existingOperacao) {
        // Soma o valor bruto à operação existente
        existingOperacao.val_bruto += val_bruto;
      } else {
        // Adiciona uma nova operação ao produtor
        existingProdutor.operacoes.push({
          operacao: dsc_tipo_proposta_emprestimo,
          val_bruto,
        });
      }
    } else {
      // Adiciona um novo produtor ao acumulador
      acc.push({
        produtor: nom_produtor_venda,
        operacoes: [
          {
            operacao: dsc_tipo_proposta_emprestimo,
            val_bruto,
          },
        ],
      });
    }
  
    return acc;
  }, [] as { produtor: string; operacoes: { operacao: string; val_bruto: number }[] }[]);

  const producaoPorProdutorConvenio = producaoData.reduce((acc, proposta) => {
    const { nom_produtor_venda, dsc_grupo, val_bruto } = proposta;
  
    // Verifica se o produtor já existe no acumulador
    const existingProdutor = acc.find(
      (item) => item.produtor === nom_produtor_venda
    );
  
    if (existingProdutor) {
      // Verifica se o convenio já existe para o produtor
      const existingConvenio = existingProdutor.convenios.find(
        (item) => item.convenio === dsc_grupo
      );
  
      if (existingConvenio) {
        // Soma o valor bruto à operação existente
        existingConvenio.val_bruto += val_bruto;
      } else {
        // Adiciona uma nova operação ao produtor
        existingProdutor.convenios.push({
          convenio: dsc_grupo,
          val_bruto,
        });
      }
    } else {
      // Adiciona um novo produtor ao acumulador
      acc.push({
        produtor: nom_produtor_venda,
        convenios: [
          {
            convenio: dsc_grupo,
            val_bruto,
          },
        ],
      });
    }
  
    return acc;
  }, [] as { produtor: string; convenios: { convenio: string; val_bruto: number }[] }[]);
  
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    })
      .format(value)
      .replace("R$", "")
      .trim();
  };  
  
  const producaoSemanal = producaoData.reduce((acc, proposta) => {
    const date = new Date(proposta.dat_confirmacao);
    const weekNumber = getWeek(date);
    const year = date.getFullYear();
    const weekKey = `${year}-W${weekNumber}`;
    if (!acc[weekKey]) {
      acc[weekKey] = { weekKey, val_bruto: 0, weekLabel: `Semana ${Object.keys(acc).length + 1}` };
    }
    acc[weekKey].val_bruto += proposta.val_bruto;
    return acc;
  }, {} as { [key: string]: { weekKey: string; val_bruto: number; weekLabel: string } });

  const producaoSemanalArray = Object.values(producaoSemanal).map((week, index, array) => {
    if (index === 0) {
      return { ...week, growth: "0.00" };
    } else {
      const previousWeek = array[index - 1];
      const growth = ((week.val_bruto - previousWeek.val_bruto) / previousWeek.val_bruto) * 100;
      return { ...week, growth: growth.toFixed(2) };
    }
  });

  return (
    <>
      <NavBarApoioCorban />
      <motion.div
        initial={{ width: 0 }}
        animate={{ width: '100%' }}
        transition={{ duration: 1.5 }}
        onAnimationComplete={handleAnimationComplete}
      >
        <Container className={`${styles.containerMain} mt-5`}>
          <Row>
            <div className="d-flex justify-content-center">
              <img src={logoVisionBi} style={{maxWidth: '270px'}}/>
            </div>
          </Row>
          <Row>
            <FilterApoioCorban onBaseChange={(base: string) => setBaseSelecionada(base)} />
          </Row>
          <Row className="mt-5">

            <Col xs={12} md={6} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card>
                    <Card.Header>Produção Total:</Card.Header>
                    <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1.6rem' }}>
                      R$ {formatCurrency(valorBruto)}
                    </Card.Body>
                  </Card>

                  <Card className="mt-5">
                    <Card.Header>Produção Média por dia:</Card.Header>
                    <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '1.6rem' }}>
                      R$ {formatCurrency(producaoMediaDia)}
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>  
            

            <Col xs={12} md={6} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card>
                    <Card.Header>Bruto X Repasse</Card.Header>
                    <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <LiquidoRepasse valorBruto={valorBruto} valorRepasse={valorRepasse} />
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
          </Row>
        
          <Row className="mt-3">
            <Col xs={12} md={6} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card>
                    <Card.Header>Produção - Convênios</Card.Header>
                    <Card.Body>
                      <ProducaoConvenioBar data={producaoPorConvenio} dataModal={producaoPorProdutorConvenio}/>
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
            <Col xs={12} md={6} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card>
                    <Card.Header>Produção - Operações</Card.Header>
                    <Card.Body>
                      <ProducaoOperacao data={producaoPorOperacao} dataModal={producaoPorProdutor}/>
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
          </Row>
        
          <Row className="mt-5">
            <Col xs={12} className="mb-4">
              {animationComplete && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card>
                    <Card.Header>Produção - Evolução semanal</Card.Header>
                    <Card.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <ProducaoTabela data={producaoSemanalArray} />
                    </Card.Body>
                  </Card>
                </motion.div>
              )}
            </Col>
          </Row>
        </Container>
      </motion.div>
    </>
  );
}
