import React, { useState, useEffect } from "react";
import { Table, Collapse } from "react-bootstrap";
import SpinnerVision from "../../Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface Proposta {
    cod_proposta_emprestimo: number;
    num_contrato: string;
    nom_banco: string;
    val_bruto: number;
    tipo_funcao: string;
    nom_produtor_venda: string;
}

interface DataBancoProdApoioCorbanProps {
    base: string | null;
}

const DataBancoProdApoioCorban: React.FC<DataBancoProdApoioCorbanProps> = ({ base }) => {
    const [bankData, setBankData] = useState<{
        nom_banco: string;
        totalAgente: number;
        totalSubstabelecido: number;
        total: number;
        parceiros: { nom_produtor_venda: string; total: number }[];
    }[]>([]);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [baseState, setBaseState] = useState<string | null>(localStorage.getItem("selectedBase"));
    const [selectedPartner, setSelectedPartner] = useState<string>(localStorage.getItem("selectedPartner") || "");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    // Verifica mudanças no selectedPartner a cada segundo
    useEffect(() => {
        const intervalId = setInterval(() => {
            const storedSelectedPartner = localStorage.getItem("selectedPartner");

            if (storedSelectedPartner !== selectedPartner) {
                setSelectedPartner(storedSelectedPartner || "");
            }
        }, 1000); // Verifica a cada segundo

        return () => {
            clearInterval(intervalId);
        };
    }, [selectedPartner]);

    useEffect(() => {
        setBaseState(base);
    }, [base]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            if (baseState) {
                try {
                    let url = `https://bi.gfttech.com.br/api/v2/apoioCorban/producao?base=${baseState}`;
                    if (selectedPartner) {
                        url += `&codProdutorVenda=${selectedPartner}`;
                    }

                    const response = await fetch(url);
                    const data: Proposta[] = await response.json();

                    const groupedData: {
                        [key: string]: {
                            nom_banco: string;
                            totalAgente: number;
                            totalSubstabelecido: number;
                            total: number;
                            parceiros: { nom_produtor_venda: string; total: number }[];
                        };
                    } = {};

                    data.forEach((proposta) => {
                        if (!(proposta.nom_banco in groupedData)) {
                            groupedData[proposta.nom_banco] = {
                                nom_banco: proposta.nom_banco,
                                totalAgente: 0,
                                totalSubstabelecido: 0,
                                total: 0,
                                parceiros: [],
                            };
                        }

                        const parceiroIndex = groupedData[proposta.nom_banco].parceiros.findIndex(
                            (p) => p.nom_produtor_venda === proposta.nom_produtor_venda
                        );

                        if (parceiroIndex === -1) {
                            groupedData[proposta.nom_banco].parceiros.push({
                                nom_produtor_venda: proposta.nom_produtor_venda,
                                total: proposta.val_bruto,
                            });
                        } else {
                            groupedData[proposta.nom_banco].parceiros[parceiroIndex].total += proposta.val_bruto;
                        }

                        if (proposta.tipo_funcao === "Agente") {
                            groupedData[proposta.nom_banco].totalAgente += proposta.val_bruto;
                        } else if (proposta.tipo_funcao === "Subestabelecido") {
                            groupedData[proposta.nom_banco].totalSubstabelecido += proposta.val_bruto;
                        }

                        groupedData[proposta.nom_banco].total += proposta.val_bruto;
                    });

                    const aggregatedData = Object.values(groupedData);
                    const sortedData = aggregatedData.sort((a, b) => b.total - a.total);

                    setBankData(sortedData);
                } catch (error) {
                    console.error("Erro ao buscar dados das propostas:", error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchData();
    }, [baseState, selectedPartner]);

    const toggleRow = (bank: string) => {
        setExpandedRow(expandedRow === bank ? null : bank);
    };

    return (
        <>
            <h5 className="text-center display-6 pb-4" style={{ fontSize: "28px" }}>
                Ranking Produção por Banco
            </h5>
            <div style={{ maxHeight: "410px", overflowY: "auto" }}>
                {isLoading ? (
                    <div className="d-flex justify-content-center">
                        <SpinnerVision />
                    </div>
                ) : (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Banco</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankData.map((bank) => (
                                <React.Fragment key={bank.nom_banco}>
                                    <tr onClick={() => toggleRow(bank.nom_banco)} style={{ cursor: "pointer" }}>
                                        <td>
                                            {expandedRow === bank.nom_banco ? (
                                                <>
                                                    <FontAwesomeIcon icon={faChevronUp} /> {bank.nom_banco}
                                                </>
                                            ) : (
                                                <>
                                                    <FontAwesomeIcon icon={faChevronDown} /> {bank.nom_banco}
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            {bank.total.toLocaleString("pt-BR", {
                                                style: "currency",
                                                currency: "BRL",
                                            })}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} style={{ padding: 0, border: 0 }}>
                                            <Collapse in={expandedRow === bank.nom_banco}>
                                                <div>
                                                    <Table striped bordered hover size="sm">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ backgroundColor: "#062173", color: "white" }}>Parceiro</th>
                                                                <th style={{ backgroundColor: "#062173", color: "white" }}>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bank.parceiros.map((parceiro) => (
                                                                <tr key={parceiro.nom_produtor_venda}>
                                                                    <td>{parceiro.nom_produtor_venda}</td>
                                                                    <td>
                                                                        {parceiro.total.toLocaleString("pt-BR", {
                                                                            style: "currency",
                                                                            currency: "BRL",
                                                                        })}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Collapse>
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                )}
            </div>
        </>
    );
};

export default DataBancoProdApoioCorban;
